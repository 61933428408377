// A single page react project to showcase work and thinking. 
// A little game for fun with the scramble button, winning once shows animated p5.js background on about page.

import React from 'react'
import Home from './components/Home.js';

function App() {
  return (
    <Home />
  );
}

export default App;
